.allJobsHeader {
  background: url("../../images/assets/alljobsBG.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-block: 5.7rem;
}

.allJobsHeader h2 {
  font-size: clamp(2rem, 3.5vw, 3rem);
  font-weight: 400;
  color: var(--company_title_color);
}

.allJobsHeader h4 {
  color: var(--main_color);
  font-weight: 600;
  font-size: clamp(2rem, 3.5vw, 3rem);
  margin-bottom: 0;
  margin-top: 0.15rem;
  padding-left: 0.5rem;
}

.allJobsHeader h5 {
  font-size: clamp(1rem, 1vw, 1.125rem);
  color: var(--company_subTitle_color);
  font-weight: 400;
  margin-top: 1rem;
}


.introComapnies {
  background-image: url('../../images/Frame\ 1597883198.png');
  height: 30vh !important;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.introComapnies h1 {
  font-size: clamp(1.1rem, 2vw + 0.8rem, 1.5rem) !important;
}

.imageCompany {
  width: 19rem !important;
}

@media screen and (max-width: 40rem) {
  .introComapnies {
    height: 25vh !important;
  }
}