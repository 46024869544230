.postingJobsContainer {
    background: url("../../images/assets/start-jobs.jpg") center no-repeat;
    background-size: cover;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.jobsBtnContainer {
    margin-right: 10rem;
    width: 20%;
}

.jobsBtnContainer h2 {
    font-weight: 600;
    /* font-size: 2.35rem; */
    font-size: clamp(1rem, 1.5rem + 0.5vw, 2.35rem);
    color: var(--white_color);
    margin-bottom: unset;
}

.jobsBtnContainer h5 {
    font-weight: 500;
    font-size: 1rem;
    color: var(--white_color);
    padding-block: 1.2rem;
}

.jobsBtnContainer a {
    background-color: var(--white_color);
    padding: .7rem 1.3rem;
    border-radius: 10px;
    color: var(--main_color);
    font-weight: 700;
    font-size: 1rem;
    border: unset;
    text-decoration: none;
}

@media screen and (max-width: 27rem) {
    .postingJobsContainer {
        background-size: cover;
        height: 40vh;
    }

    .jobsBtnContainer {
        margin-right: 0;
        width: 55%;
    }

    .jobsBtnContainer h5 {
        padding-block: 0.5rem;
    }
}