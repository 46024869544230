.AsideContainer {
  flex: 0 0 30% !important;
  border: 1px solid rgba(128, 128, 128, 0.507);
  /* margin-top: 5rem !important; */
  margin-block: 30px;
  padding: 15px;
}

.AsideContainer p {
  font-size: 1.1rem;
  color: var(--main_color);
  font-weight: 700;
}

.mainContainer {
  /* flex: 1 !important; */
  width: 65.5%;
  /* border: 1px solid red; */
}

.contactH1 {
  color: var(--main_color);
}

.mainContainer {
  border: 1px solid rgba(128, 128, 128, 0.507);
  /* margin-top: 5rem !important; */
  margin-block: 30px;
  padding: 15px;
}

.mainContainerProfile {
  width: 95% !important;
  margin: auto !important;
}

.pointDiv {
  box-shadow: 1.93px 1.93px 5.79px 0px #00000026 !important;
  margin-left: 15.6rem;
  padding: 30px !important;
  width: 35% !important;
  margin-top: 20px !important;
}

.button_outline {
  background-color: white !important;
  color: var(--main_color) !important;
  border: 1px solid var(--main_color) !important;
}

.userProfileImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.DetailsAndButton {
  align-items: end !important;

}

@media (max-width: 768px) {
  .DetailsAndButton {
    flex-direction: column !important;
    align-items: center !important;
  }

  .pointDiv {
    margin: 0px !important;
    width: 100% !important;
  }

  .DetailsAndButton {
    align-items: center !important;
  }

  .mainContainer {
    /* flex: 1 !important; */
    width: 100%;
    /* border: 1px solid red; */
  }

  .PersonalInfo {
    font-size: 10px !important;
  }

  .mobileViewContent {
    flex-direction: column !important;
  }

  .fontSizeMobileView {
    font-size: 10px;
  }
}