.signInContainer {
  height: fit-content !important;
  overflow: hidden;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.9rem;
}

.formHeader {
  margin-bottom: .85rem;
  text-align: center;
}

.tab {
  color: var(--main_color) !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.tab:focus,
.tab:active {
  color: var(--main_color) !important;
  background-color: #E9EBFD !important;
  text-decoration-line: unset !important;
}

.toggleContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.formHeader h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--header_color);
  padding-block: clamp(0.5rem, 1vw, 0.75rem);
}

.formHeader h5 {
  font-weight: 400;
  opacity: 50%;
  font-size: clamp(0.65rem, 2vw, 1rem);
  color: var(--header_color);
  margin-block: 1rem;
}

.inputLabel {
  color: var(--company_subTitle_color) !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  margin-bottom: .35rem !important;
}

.logInBtn {
  color: var(--white_color);
  border: unset;
  font-weight: 700;
  font-size: clamp(0.9rem, 1vw, 1.2rem);
  margin-bottom: .6rem;
  background-color: var(--main_color);
  padding: .75rem 1.5rem;
  width: 50%;
}

.signUpQ {
  color: var(--header_color);
  font-size: 1rem;
  font-weight: 400;
}

.signUpLink {
  color: var(--main_color) !important;
  font-weight: 600;
  font-size: 1rem !important;
  padding-left: 5px;
}

.alertMessage {
  color: red;
  font-weight: 600;
}

@media only screen and (max-width: 48rem) {
  .imgContainer {
    display: none;
  }

  .formContainer {
    width: 100%;
    justify-content: center;
  }
}