.huntHeader {
    background: url("../../images/assets/alljobsBG.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-block: 5.7rem;
}

.huntHeader h2 {
    font-size: clamp(2rem, 3.5vw, 3rem);
    font-weight: 400;
    color: var(--company_title_color);
}

.huntHeader h4 {
    color: var(--main_color);
    font-weight: 600;
    font-size: clamp(2rem, 3.5vw, 3rem);
    margin-bottom: 0;
    margin-top: 0.15rem;
    padding-left: 0.5rem;
}

.huntHeader h5 {
    font-size: clamp(1rem, 1vw, 1.125rem);
    color: var(--company_subTitle_color);
    font-weight: 400;
    margin-top: 1rem;
}

.filtersContainer {
    margin-top: 2rem;
    margin-inline: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border_color);
}

.selectCategories {
    color: var(--white_color);
    font-size: 1rem;
    font-weight: 400;
    background: var(--select_bg);
    border: 1.1px solid var(--sub_header);
    border-radius: 2.5rem;
    padding: 0.3rem;
    margin-inline: 0.75rem;
}

.selectCategories option {
    font-size: 16px;
    background-color: #ffffff;
    color: var(--main_color);
}

.checkboxContainer {
    display: flex;
    align-items: center;
}

.checkboxInput {
    width: 1.1rem;
    height: 1.1rem;
}

.checkboxLabel {
    margin-inline: 0.75rem;
    font-size: 1.25rem;
    color: var(--main_color);
}

.selectedFiltersContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 1rem;
}

.selectedFilter {
    margin-top: 1rem;
    display: inline-block;
    background-color: #e0e0e0;
    padding: 5px 10px;
    margin-right: 10px;
    border: 1px solid var(--main_color);
    border-radius: 1.25rem;
}

.selectedFilter button {
    margin-left: 5px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--main_color);
    font-weight: bold;
}

.clearAllButton {
    display: block;
    width: fit-content;
    font-weight: 500;
    color: var(--main_color);
    border-radius: 2.5rem;
    margin-top: 10px;
    background-color: var(--border_color);
    border: 1px solid var(--main_color);
    padding: 0.35rem 1rem;
    cursor: pointer;
}

.candidatesContainer {
    margin: 1rem;
}

.candidatesHeader {
    margin-block: 2rem;
    text-align: center;
}

.candidatesHeader h2 {
    color: var(--company_title_color);
    font-size: 2rem;
    font-weight: 600;
}

.candidatesHeader h4 {
    color: var(--sub_header);
    font-size: 1rem;
    font-weight: 400;
}

.candidatesList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.candidateCard {
    display: flex;
    border: 1.22px solid var(--border-bottom);
    background: var(--white_color);
    padding: 1rem;
    width: 32%;
    min-height: 13rem;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.candidateCard>img {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
}

.candidateName {
    margin: 1rem 0;
}

.candidateInfo {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.candidateInfo h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--main_color);
}

.candidateInfo h4 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--sub_header);
}

.candidateMoreInfo {
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.candidateMoreInfo img {
    margin-right: 1rem;
}

.candidateMoreInfo h6 {
    margin: 0;
}

.buttonViewProfile {
    background-color: var(--main_color);
    color: #fff;
    border: 1px solid var(--main_color);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    text-align: center;
    width: fit-content;
    align-self: flex-start;
    margin-top: auto;
}

@media only screen and (max-width: 48rem) {
    .candidateCard {
        width: 100%;
    }

    .filtersContainer {
        flex-direction: column !important;
    }

}