.attachmentContainer {
    padding: 1rem 0;
}

.attachment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.attachmentTitle {
    font-size: 1rem;
    color: #2c3e50;
    font-weight: 600;
    margin: 0;
}

.attachmentLink {
    display: flex;
    align-items: center;
    color: #3498db;
    text-decoration: none;
    font-weight: 500;
}

.attachmentLink:hover {
    color: #2980b9;
    text-decoration: underline;
}

.icon {
    color: #e74c3c;
    margin-right: 8px;
    font-size: 1.2rem;
}

.separator {
    border: none;
    border-top: 1px solid #ddd;
    margin: 0.5rem 0;
}