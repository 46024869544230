.whyUseContainer {
  margin-block: 3rem;
  padding-inline: 5rem;
  /* margin-left: 2rem; */
}

.whyUseContainer h2 {
  color: #25324B;
  font-size: 2.3rem;
  font-weight: 600;
  padding-bottom: 2.5rem;
  text-align: center;
  /* padding-left: 3rem; */
}

.portalBenefits {
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.points {
  width: 48%;
  height: 13rem;
  border: 1px solid #D6DDEB;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 2rem;
}

.points img {
  width: 3.875rem;
}

.points span {
  font-size: clamp(1rem, 1rem + 0.5vw, 2rem);
  font-weight: 500;
  color: #25324B;
}

@media screen and (max-width: 48rem) {
  .whyUseContainer {
    margin-block: 2rem;
    padding-inline: 2rem;
  }

  .points {
    padding: 1rem;
  }

  .whyUseContainer h2 {
    font-size: 1.75rem;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 32rem) {
  .points {
    height: auto;
    width: auto;
  }
}