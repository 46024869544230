.dashboardBackground {
  background-image: url("../../images/assets/alljobsBG.jpg");
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  background-position: center;
  background-size: inherit;
  background-repeat: no-repeat;
}

.companyCard {
  border: 1px solid #D6DDEB;
  background-color: #ffff;
  padding: 15px;
  width: 80%;

}

.buttonJobs {
  background-color: #ffff;
  color: var(--main_color);
  padding: 5px !important;
  border: 1px solid #D6DDEB;

}

.buttonJobsDiv {
  width: 80% !important;
  margin-bottom: 20px !important;
}

.noDataMessage {
  text-align: center;
  color: #888;
  margin-top: 20px;
  font-size: 16px;
}