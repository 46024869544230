.tab {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    color: var(--company_title_color) !important;
    margin-inline: 1.25rem !important;

}

.tabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 1rem !important;
}

.crumbsContainer {
    margin: 1rem 2rem !important;
}

.postJobBtn {
    font-weight: 700;
    color: var(--white_color);
    font-size: 1rem;
    background-color: var(--main_color);
    border: unset;
    padding: 0.75rem 1.5rem;
}