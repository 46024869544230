.loadingContainer {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingContainer h1 {
    font-size: 3rem;
    color: var(--main_color);
    font-weight: 700;
}