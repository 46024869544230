.AsideContainer {
  flex: 0 0 30% !important;
  border: 1px solid rgba(128, 128, 128, 0.507);
  /* margin-top: 5rem !important; */
  margin-block: 30px;
  padding: 15px;
}

.mainContainer {
  /* flex: 1 !important; */
  width: 65.5%;
  /* border: 1px solid red; */
}

.contactH1 {
  color: var(--main_color);
}

.mainContainer {
  border: 1px solid rgba(128, 128, 128, 0.507);
  /* margin-top: 5rem !important; */
  margin-block: 30px;
  padding: 15px;
}

.mainContainerProfile {
  width: 95% !important;
  margin: auto !important;
}
.pointDiv {
  box-shadow: 1.93px 1.93px 5.79px 0px #00000026 !important;
  margin-left: 19rem;
  padding: 30px !important;
  width: 35% !important;
  margin-top: 20px !important;
}
.button_outline {
  background-color: white !important;
  color: var(--main_color) !important;
  border: 1px solid var(--main_color) !important;
}

.userProfileImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.pointsInfo {
  padding: 10px 16px;
  color: var(--main_color);
}
