#partnersContainer {
    min-height: 63vh;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
}

.partnerImg {
    width: 95%;
}

.imgContainer {
    box-shadow: 2px 7px 0.2em #e5e5e5;
    border-radius: 1.5px;
    margin: 1rem 2rem;
    max-width: 11rem;
    min-width: 9rem;
    min-height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1px;

}

.imgContainer:hover {
    transform: scale(1.1);
    box-shadow: 5px 10px 0.4em #e6d88f;
    transition: all ease 0.4s;
}