.companyProfileBackdround {
  background-color: #F5F5F5;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.contact_div {
  /* border: 1px solid yellow !important; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5px !important;
  border-radius: 5px !important;
  width: fit-content;
}

.companyName {
  color: var(--company_title_color);
  font-weight: 600;
  font-size: 1.75rem;
}

.companyProfileDetails {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 1.5rem;
}

.btnSection {
  margin-bottom: 1.75rem;
  display: flex;
  justify-content: space-between;
}

.addJobBtn {
  color: var(--main_color);
  font-weight: 700;
  font-size: 1rem;
  border: 1px solid var(--main_color);
  border-radius: 0.65rem;
  background-color: transparent;
  padding: 0.785rem 1.375rem;
}

.postJobBtn {
  font-weight: 700;
  color: var(--white_color);
  font-size: 1rem;
  background-color: var(--main_color);
  border: unset;
  padding: 0.75rem 1.5rem;
}

.companyImagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-block: 1.5rem;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.mainImage,
.subImage {
  width: 100%;
  max-width: 20rem;
  height: auto;
  object-fit: cover;
  flex: 1;
}

@media screen and (max-width: 48rem) {
  .imageWrapper {
    flex-direction: column;
    align-items: center;
  }

  .mainImage,
  .subImage {
    max-width: 90%;
  }
}

@media screen and (max-width: 27rem) {
  .postJobBtn {
    padding: 0.5rem 1rem;
  }

  .addJobBtn {
    padding: 1rem;
  }

  .companyImagesContainer {
    flex-direction: column;
    gap: 1rem;
  }
}