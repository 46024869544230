.personalInfoContainer {
    margin: 2rem .5rem;
    width: 90%;
}

.personalInfoContainer h2 {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
}

.personalInfoContainer h3 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.personalDetailsSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.formSection {
    width: 50%;
}

.personalInfoHeader,
.photoSection {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
}

.personalInfoHeader h2 {
    font-size: 1.125rem;
}

.photoSection {
    display: flex;
    justify-content: space-between;
}

.photoHead {
    width: 25%;
}

.photoSection h3 {
    color: var(--company_subTitle_color);
}

.uploadImgSection {
    border: 2px dashed var(--main_color);
    padding: 1.5rem 2.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
}

.uploadImgSection h4 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--company_subTitle_color);
}

.uploadImgSection span {
    color: var(--company_title_color);
}

.uploadImgSection h5 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.saveBtnSection {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid var(--border_color);
}

.test {
    width: 100%;
}

.saveBtnSection button {
    font-weight: 700;
    font-size: 1.125rem;
    border-radius: 0.625rem;
    padding: 0.875rem 2.5rem;
    background-color: var(--main_color);
    border: unset;
    color: var(--white_color);
}

.txtField,
.selectFieldLarge,
.selectFieldSmall {
    margin: 0 !important;
    border: 1px solid var(--border_color) !important;
    color: var(--white_color) !important;
}

.fieldsName {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

.addressContainer {
    display: flex !important;
    gap: 1rem !important;
}

.addressSection {
    margin: 0 !important;
    border: 1px solid var(--border_color) !important;
    color: var(--white_color) !important;
    width: 30%;
}

.profileImage {
    width: 100px;
    height: 100px;
    border-radius: 50px !important;

}

.uploadLabel {
    cursor: pointer;
}

@media screen and (max-width:50rem) {
    .personalInfoContainer {
        margin: 1rem .5rem;
        width: auto;
    }

    .photoSection {
        justify-content: unset;
        flex-wrap: wrap;
    }

    .personalDetailsSection {
        justify-content: unset;
        gap: 2rem;
    }

    .formSection {
        width: auto;
    }

    .addressSection {
        width: 50%;
    }
}

@media screen and (max-width: 45rem) {
    .photoHead {
        width: auto;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 38rem) {
    .personalDetailsSection {
        flex-direction: column;
        gap: 1.2rem;
    }
}