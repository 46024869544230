.jobDetailsHead {
    background: url("../../images/assets/alljobsBG.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-block: 2.5rem;
    height: 50vh;
}

.jobDetailNavigate {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.shareAndApply {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.shareButtons {
    display: flex;
    gap: 0.3rem;
}

.addJobBtn {
    color: var(--main_color);
    font-weight: 700;
    font-size: 1rem;
    border: 1px solid var(--main_color);
    border-radius: 0.65rem;
    background-color: transparent;
    padding: 0.785rem 1.375rem;
}

.dashboardBtn {
    border: 0.86px solid var(--bg_color);
    background-color: var(--main_color);
    color: var(--bg_color);
    font-weight: 700;
    font-size: 0.9rem;
    border-radius: 5px;
    padding: 0.65rem 0.86rem;
}

.breadcrumbsContainer {
    align-self: flex-start;
    padding-left: 8rem;
    margin-bottom: 1rem;
}

.breadcrumbsContainer h3 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
}

.jobCardDetails {
    display: flex;
    border: 1px solid var(--border_color);
    width: fit-content;
    background-color: var(--white_color);
    padding: 1.1rem;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.jobTitle {
    display: flex;
    gap: 1.6rem;
}

.jobTitle h2 {
    color: var(--company_title_color);
    font-size: 2rem;
    font-weight: 600;
}

.jobTitle h5 {
    color: var(--company_subTitle_color);
    font-size: 1.25rem;
    font-weight: 400;
}

.circleDot {
    width: 4px;
    height: 4px;
    background-color: var(--company_subTitle_color);
    border-radius: 50%;
}

.applyBtn {
    border: unset;
    color: var(--white_color);
    font-size: 1rem;
    font-weight: 700;
    background-color: var(--main_color);
    padding: 0.9rem 3.5rem;
    height: fit-content;
    border-radius: 10px;
    margin-left: 1rem;
}

.imageBorder {
    border-right: 1px solid var(--border_color);
    padding-right: 1rem;
}

.jobDetailsInfo {
    display: flex;
    gap: 1.5rem;
    margin: 2rem;
}

.mainInfo {
    flex: 1;
    padding-right: 1.5rem;
    padding-left: 1rem;
}

.mainInfo div {
    margin-bottom: 1.8rem;
}

.mainInfo h2,
.asideInfo h2 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 2rem;
    padding-bottom: 1.2rem;
    padding-top: 1rem;
}

.mainInfo p,
.mainInfo li {
    font-weight: 400;
    font-size: 1rem;
    color: var(--company_subTitle_color);
}

.mainInfo li {
    /* list-style: url("../../images/assets/LiIcon.svg"); */
    background: url("../../images/assets/LiIcon.svg") no-repeat left center;
    padding-left: 1.75rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
}

.benefitsInfo h2 {
    padding-bottom: .2rem;
}

.benefitsInfo p {
    padding-bottom: 1.5rem;
}

.benefitsCards {
    display: flex;
    flex-wrap: wrap;
    gap: 1.1rem;
}

.benefitsCards h5 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 1.25rem;
    border-radius: 4px;
    border: 1px solid var(--secondary_color);
    padding: 1.1rem 1rem;
    width: 35%;
}

.asideInfo {
    flex: 0 0 28%;
}

.appliedJobs {
    padding: 1rem;
    background-color: var(--bg_color);
    margin-bottom: 2rem;
}

.appliedJobs h3,
.asideJobInfo h3 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--sub_header);
}

.appliedJobs span,
.asideJobInfo span {
    font-weight: 600;
    color: var(--company_title_color);
}

.asideJobInfo {
    display: flex;
    justify-content: space-between;
}

.categoriesInfo h4 {
    font-weight: 600;
    font-size: 0.85rem;
    color: var(--main_color);
    border-radius: 5rem;
    width: fit-content;
    padding: 0.375rem 0.625rem;
    background: var(--bg_color);
}

.skillsInfo h4 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--main_color);
    width: fit-content;
    padding: 0.25rem 0.75rem;
    background: var(--bg_color);
}

.companyInfo {
    background: url("../../images/assets/companyInfoBG.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1.5rem;
    margin-bottom: 1rem;
}

.companyInfoContainer {
    display: flex;
    padding-inline: 1.5rem;
}

.companyIntro {
    margin-block: auto;
    width: 70%;
}

.companyTitle {
    display: flex;
    gap: 1.7rem;
}

.companyTitle h2 {
    color: var(--company_title_color);
    font-size: 2rem;
    font-weight: 600;
}

.companyTitle a {
    text-decoration: unset;
    color: var(--main_color);
    font-weight: 600;
    font-size: 1rem;
}

.companyLogo {
    width: 15%;
    object-fit: contain;
}

.companyIntro p {
    color: var(--company_subTitle_color);
    font-weight: 400;
    font-size: 1rem;
    width: 87%;
}

.companyPhotos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imagesContainer {
    display: flex;
    gap: 10px;
}

.leftImageElement,
.rightImageElement {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: cover;
    flex: 1;
}

@media screen and (max-width: 55rem) {
    .jobCardDetails {
        width: 90%;
    }

    .breadcrumbsContainer {
        padding-left: 2.5rem;
    }

    .jobDetailsContainer h2 {
        font-size: 1.35rem;
    }

    .jobDetailsContainer ul {
        padding-left: 1rem;
    }

    .jobDetailsInfo {
        gap: 0.75rem;
        margin: 0.5rem;
    }

    .benefitsInfo p {
        padding-bottom: 1rem;
    }

    .benefitsCards h5 {
        width: fit-content;
    }

    .companyInfo {
        padding: 1rem;
    }

    .companyTitle {
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .companyIntro p {
        width: auto;
        padding-bottom: .5rem;
    }

    .companyInfoContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 48rem) {
    .benefitsCards h5 {
        width: 46%;
        padding: 0.5rem;
    }

    .jobTitle h5 {
        font-size: 1.1rem;
    }

    .asideInfo {
        flex: 0 0 32%;
    }

    .mainInfo {
        padding-inline: 0.5rem;
    }

    .imagesContainer {
        flex-direction: column;
        align-items: center;
    }

    .leftImageElement,
    .rightImageElement {
        max-width: 90%;
    }
}

@media screen and (max-width: 27rem) {
    .jobCardDetails {
        display: contents;
    }

    .jobTitle {
        display: flex;
        gap: 1.6rem;
    }

    .shareAndApply {
        margin-top: .5rem;
    }

    .jobDetailsInfo {
        flex-direction: column;
        margin: 0.5rem;
    }

    .mainInfo div {
        margin-bottom: 1.5rem;
    }

    .mainInfo {
        padding: .5rem;
    }
}