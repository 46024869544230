  .bgFooter {
    padding: 20px;
    background: #263997;
    background-size: contain;
  }

  .footerImage {
    object-fit: contain;
    width: fit-content !important;
  }

  .footerImage img {
    width: 70%;
    object-fit: contain;
  }

  .layer {
    width: 84%;
    margin: auto;
  }

  .footerMenu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .alltags {
    color: gray;
    clear: both;
  }

  .tag {
    display: block;
    float: left;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 2px 10px;
    font-size: 13px;
    text-decoration: none;
    border: #6c757d solid 1px;
    border-radius: 3px;
    color: #6c757d;
    transform: all 0.5s;
  }

  .tag:hover {
    color: #076cec;
    border: #076cec solid 1px;
  }

  .lastFooter {
    background-color: #263997;
  }

  .allIcons>div>div {
    gap: 10px !important;

  }

  #root>footer>div.bgFooter>div>div>div:nth-child(2)>div:nth-child(3)>div>div {
    width: 50%;
    gap: 20px;
  }

  .logo-footer {
    width: 40% !important;
  }

  .lastFooterP {
    color: white;
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
  }

  .iconSpanFac,
  .iconSpantw,
  .iconSpanGo,
  .iconSpanin {
    padding: 5px !important;
    color: rgb(255, 255, 255);
    margin-left: 5px !important;
    cursor: pointer;
  }

  .iconSpanFac:hover {
    color: rgba(71, 71, 214, 0.767);
    transition: all 1s ease-in;
  }

  .iconSpantw:hover {
    color: rgb(24, 113, 214);
    transition: all 1s ease-in;
  }

  .iconSpanGo:hover {
    color: rgb(177, 12, 12);
    transition: all 1s ease-in;
  }

  .iconSpanin:hover {
    color: #f14141;
    transition: all 0.4s ease-in;
  }

  .firstBut {
    display: flex;
    justify-content: center;
    width: 65%;
    padding: 5px;
    margin-bottom: 20px;
    background-color: #fcae3b;
    cursor: pointer;
    border-radius: 5px;
  }

  .secondBtn {
    display: flex;
    justify-content: center;
    width: 65%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #1864d2;
    cursor: pointer;
    border-radius: 5px;
  }

  .firstBut:hover {
    background-color: #1864d2;
  }

  .secondBtn:hover {
    background-color: #fcae3b;
  }

  @media only screen and (max-width: 700px) {
    .bgFooter {
      text-align: center;
    }

    .bgFooter ul {
      list-style: none;
    }

    .allIcons {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .alltags {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .lastFooterP {
      text-align: center;
    }

    .lastFooter:before {
      display: none;
    }

    .icon-dev-res {
      justify-content: center;
    }

    .mobile-view-border {
      border: none !important;
    }

    .imageFooter {
      width: 95% !important;
    }

    .layin-lastfooter {
      flex-direction: column !important;
      gap: 20px !important;
    }
  }

  a {
    /* color: white; */
    text-decoration: none;
  }

  /* .lastFooter:before {
    position: absolute;
    content: "";
    width: 80%;
    height: 2px;
    background-color: #ffffff38;
    bottom: 20%;
    left: 10%;
  } */

  /* .bgFooter{
    border-bottom: 1px solid rgba(255, 255, 255, 0.363) !important;    
  } */
  .layin-lastfooter {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
  }

  .border-left {
    border-left: 2px solid var(--secondary_color);
    padding: 3px !important;
  }

  .first-div-bg {
    height: 35px;
    background-color: #F7BE1E;
  }

  .footerLocation {
    display: flex;
    align-items: flex-start;
  }