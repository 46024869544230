.backgroundNavbar {
  background-color: white;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9999;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.imageLogo {
  width: 5.5rem;
  cursor: pointer;
}

.menuButton {
  background: none;
  border: none;
  display: none;
  cursor: pointer;
  color: var(--main_color) !important;
}

.navLinks {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.ulStyles {
  list-style: none;
  display: flex;
  justify-content: space-around;
  /* width: 50%; */
  margin: 0;
}

.webMenu {
  display: flex;
}

.activeLink {
  color: var(--secondary_color) !important;
  text-decoration: underline !important;
  text-decoration-color: var(--secondary_color) !important;
}

.ulStyles a {
  color: var(--main_color);
  text-decoration: none;
  font-weight: 600 !important;
  /* color: white !important; */
}

.login_button {
  color: var(--main_color);
  text-decoration: none;
  margin-right: 20px !important;
}

.buttonRequest {
  background-color: var(--main_color);
  border: 1px solid var(--main_color);
  border-radius: 10px;
  color: white !important;
  padding: 0.6rem 1.2rem;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
}

.buttonRequest:hover {
  color: white !important;
  cursor: pointer;
}

.dropdown {
  position: relative;
}

.dropdownNew {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-inline: 20px !important;
  margin-block: 20px !important;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
}

.dropdownMenuNew {
  position: absolute;
  top: 100%;
  right: 0px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 11% !important;
}

.dropdownItem {
  padding: 10px;
  display: block;
  color: #333;
  text-decoration: none;
  border: none;
  background: none;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.ulStyles li {
  margin: 0px 20px;
}

.hideOnMobile {
  display: flex;
  align-items: center;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .webMenu {
    display: none;
  }

  .navLinks {
    display: none;
    width: 100%;
  }

  .showMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    /* Adjust based on your navbar height */
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .menuButton {
    display: block;
  }

  .ulStyles {
    flex-direction: column;
    align-items: center;
    color: white !important;
  }

  .ulStyles li {
    margin: 10px 0px;
  }

  .d-flex {
    justify-content: space-between;
    width: 100%;
  }

  .backgroundNavbar {
    padding: 5px 0.75rem;
  }

  .hideOnMobile {
    display: none !important;
  }
}