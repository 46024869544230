.professionalInfoContainer {
    margin: 2rem .5rem;
    width: 90%;
}

.professionalInfoContainer h2 {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
}

.professionalInfoContainer h3 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.personalInfoHeader,
.aboutMeSection,
.experienceSection,
.jobDetailsSections,
.educationSection,
.educationDetailsSection,
.skillsSection,
.languageSection,
.certificationSection,
.certificationsDetails {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
}

.personalInfoHeader h2 {
    font-size: 1.125rem;
}

.aboutMeSection {
    display: flex;
    justify-content: space-between;
}

.aboutMeTxt {
    width: 50% !important;
    color: #A8ADB7 !important;
}

.experienceSection {
    display: flex;
    flex-direction: column;
}

.experienceHeaderSection {
    width: 22%;
}

.personalDetailsSection {
    width: 30% !important;
    border: 1px solid rgba(128, 128, 128, 0.178);
    padding: 20px;
    border-radius: 20px;
}

.personalDetailsSection h4 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 1rem;
}

.txtFieldContainer {
    margin-bottom: 1.5rem !important;
}

.txtField {
    width: 100% !important;
    margin-bottom: .5rem;
}

.dateInput {
    padding: .75rem 1rem;
    color: var(--company_subTitle_color) !important;
    background: var(--white_color);
    border: 1px solid var(--border_color)
}

.checkbox {
    margin-block: 1rem;
}

.referenceInfo {
    margin-bottom: 1rem;
}

.btnSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-block: 1rem;
}

.addJobBtn {
    color: var(--main_color);
    font-weight: 700;
    font-size: 1rem;
    border: 1px solid var(--main_color);
    border-radius: 0.6rem;
    background-color: var(--white_color);
    padding: 0.785rem 1.375rem;
    width: 25%;
}

.saveBtn {
    font-weight: 700;
    color: var(--white_color);
    font-size: 1.125rem;
    background-color: var(--main_color);
    border: unset;
    border-radius: 10px;
    padding: 0.8rem 3rem;
}

.jobDetailsSections {
    display: flex;
    justify-content: space-between;
}

.referenceSection {
    display: flex;
    border: 1px solid var(--border_color);
    padding: 1rem;
    gap: 1rem;
}

.jobDetailsSections h4 {
    color: var(--company_title_color);
    font-size: 1rem;
    font-weight: 500;
}

.referenceSection h5 {
    color: var(--sub_header);
    font-size: 0.875rem;
    font-weight: 400;
}

.educationSection {
    display: flex;
    flex-direction: column;
}

.educationHeaderSection {
    width: 22%;
}

.educationBtnSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.educationDetailsSection {
    display: flex;
    justify-content: space-between;
}

.degreeInfo {
    padding-top: .5rem;
    display: flex;
    gap: 0.6rem;
    flex-wrap: wrap;
}

.degreeInfo h4 {
    font-size: 0.875rem;
    color: var(--company_title_color);
    font-weight: 500;
}

.degreeInfo span {
    color: var(--main_color);
}

.skillsSection {
    display: flex;
    gap: 4rem;
}

.languageSection {
    display: flex;
    gap: 5rem;
}

.certificationSection {
    display: flex;
    gap: 1rem;
}

.certificationSection .dateInput {
    padding: .75rem 1rem;
}

.certificationsDetails {
    display: flex;
    justify-content: space-between;
}

.certificationsDetails h4 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--company_subTitle_color);
}

.certificationsDetails h5 {
    color: var(--company_title_color);
    font-size: 0.875rem;
    font-weight: 500;
}

@media screen and (max-width:50rem) {
    .professionalInfoContainer {
        margin: 1rem .5rem;
        width: auto;
    }
}

.seeMoreContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.seeMoreBtn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.seeMoreBtn:hover {
    background-color: #0056b3;
}
/* Add these styles to ProfessionalInfo.module.css */

.skillsSection {
    margin-top: 20px;
  }
  
  .skillsHeaderSection {
    margin-bottom: 10px;
  }
  
  .skillsInputSection {
    margin-bottom: 10px;
  }
  
  .selectedSkillsSection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .skillChip {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 15px;
  }
  