.cardJobDashboard {
  background-color: #F5F5F5;
  border-radius: 20px !important;
  padding: 20px;
  width: 100% !important;
  gap: 20px !important;
}

.cardJobDashboard2 {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
}

.buttonInConsideration {
  background-color: transparent;
  color: var(--main_color);
  border: 1px solid var(--main_color);
  border-radius: 5px !important;
  margin-right: 10px;
  padding: 5px 10px;
}

.buttonRemoveFromPipeline {
  background-color: transparent;
  color: red;
  border: 1px solid red;
  border-radius: 5px !important;
  padding: 5px 10px;
}

.buttonViewProfile {
  background-color: var(--main_color);
  color: #fff;
  border: 1px solid var(--main_color);
  border-radius: 5px !important;
  padding: 5px 10px;
}

.iconColors {
  color: var(--main_color);
  font-size: 20px !important;
  margin-right: 10px !important;
}

.piplinesDivFilter {
  width: 30% !important;
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 20px !important;
  margin-left: 20px !important;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.fullHeight {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noCandidates {
  min-height: 120px;
  /* Adjust this value based on your card height */
}

.userProfileImageContainer {
  width: 20%;
}

.userProfileImage {
  width: 100%;
  border-radius: 50%;
}