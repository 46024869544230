.banner {
  height: 132px;
  background: rgba(254, 239, 221, 0.35);
  box-shadow: inset 11px 26px 3px -32px rgba(0, 0, 0, 0.25);
  position: relative;
}

.bannerContent {
  display: flex;
  align-items: flex-end;
  width: 85%;
  position: absolute;
  left: 7.5%;
  top: 84px;
}

.uploadPosition {
  position: relative;
  top: 18px;
}

.detailsContent {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 48px auto 20px;
  padding-top: 30px;
  min-height: 30vh;
}

.inputWidth {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .inputContainer {
    flex-direction: column;
  }
  .inputWidth {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 375px) {
  .uploadPosition {
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .actions {
    margin: 3px !important;
  }
  .bannerContent {
    align-items: center;
    justify-content: space-around;
  }
}

.actions {
  font-weight: bold;
  margin: 0px 3px;
}
