.courseCertification {
  border: 1px solid rgba(0, 0, 0, 0.425);
  border-radius: 5px !important;
  width: 200px !important;
  padding: 20px !important;
  background-image: url('../../../images/certifcate.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}


.OnlineCourses {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.coursesOnlineCertifications {
  display: flex !important;
  /* justify-content: space-around; */
  gap: 25px !important;
  flex-wrap: wrap;
  padding: 20px;
}

.h6Headline {
  color: var(--main_color);
  font-size: 20px !important;
  font-weight: 900 !important;
}

.buttonCertificates {
  height: 5vh !important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.buttonCertificates .LaunchIcon {
  font-size: 11px !important;
}

.offlineCourseList {
  margin-top: 20px !important;
  display: flex;


}

.courseCertification {
  margin-inline: 20px !important;
}