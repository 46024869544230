.cardJobDashboard {
  background-color: #F5F5F5;
  border-radius: 20px !important;
  padding: 20px;
}

.cardJobDashboard2 {
  display: flex;
  justify-content: space-around;
}

.buttonAddToPipline {
  background-color: transparent;
  color: var(--main_color);
  border: 1px solid var(--main_color);
  border-radius: 5px !important;
}

.buttonViewProfile {
  background-color: var(--main_color);
  color: #fff;
  border: 1px solid var(--main_color);
  border-radius: 5px !important;
}

.iconColors {
  color: var(--main_color);
  font-size: 20px !important;
  margin-right: 10px !important;
}

.userProfileImageContainer {
  width: 15%;
}

.userProfileImage {
  width: 100%;
  border-radius: 50%;
}