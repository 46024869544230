.archivedJobContainer {
    margin: 1rem;
    display: flex;
    gap: 1.3rem;
    flex-wrap: wrap;
    justify-content: center;
}

.singleJobContainer {
    border: 1px solid var(--border_color);
    width: fit-content;
    padding: 1.5rem;
    border-radius: 10px;
    width: 48.5%;
    text-decoration: none;
}

.logoSection {
    display: flex;
    justify-content: space-between;
    padding-bottom: .8rem;
}

.logoSection img {
    width: 10%;
}

.jobTitle h2 {
    color: var(--company_title_color);
    font-size: 1.125rem;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}

.jobTitle h3 {
    color: var(--company_subTitle_color);
    font-size: 1rem;
    font-weight: 400;
}

.jobTitle p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--company_subTitle_color);
    margin-block: .8rem;
}

.jobDescription {
    display: flex;
    gap: 1rem;
}

.jobDescription h4 {
    width: fit-content;
    height: fit-content;
    color: var(--main_color);
    font-weight: 600;
    font-size: .85rem;
    border-radius: 10px;
    padding: 0.3rem 0.9rem;
    background-color: var(--bg_color);
}

.btnSections {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.editJobBtn {
    color: var(--main_color);
    border: 0.86px solid var(--main_color);
    border-radius: 5px;
    padding: 0.65rem 0.86rem;
    background-color: var(--white_color);
    font-weight: 700;
    font-size: 0.9rem;
}

.dashboardBtn {
    border: 0.86px solid var(--bg_color);
    background-color: var(--main_color);
    color: var(--bg_color);
    font-weight: 700;
    font-size: 0.9rem;
    border-radius: 5px;
    padding: 0.65rem 0.86rem;
}

.jobStatus {
    border-radius: 5px;
    padding: 0.25rem 0.75rem;
    border: 1px solid var(--main_color);
    background-color: var(--bg_color);
    color: var(--main_color);
}

@media screen and (max-width: 55rem) {
    .singleJobContainer {
        width: 80%;
    }
}

@media screen and (max-width: 34rem) {
    .singleJobContainer {
        width: 95%;
        padding: 0.8rem;
    }

    .activeJobContainer {
        gap: 1rem;
    }

    .editJobBtn,
    .dashboardBtn {
        padding: 0.5rem;
    }
}