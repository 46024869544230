.allJobsHeader {
    background: url("../../images/assets/alljobsBG.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-block: 5.7rem;
}

.allJobsHeader h2 {
    font-size: clamp(2rem, 3.5vw, 3rem);
    font-weight: 400;
    color: var(--company_title_color);
}

.allJobsHeader h4 {
    color: var(--main_color);
    font-weight: 600;
    font-size: clamp(2rem, 3.5vw, 3rem);
    margin-bottom: 0;
    margin-top: 0.15rem;
    padding-left: 0.5rem;
}

.allJobsHeader h5 {
    font-size: clamp(1rem, 1vw, 1.125rem);
    color: var(--company_subTitle_color);
    font-weight: 400;
    margin-top: 1rem;
}

.jobsAndFiltersContainer {
    display: flex;
    gap: 1rem;
}

.filterSideBar {
    flex: 0 0 23%;
    padding: 1rem;
    margin-block: 1.5rem;
}

.filterSideBar h3 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--company_title_color);
    margin-bottom: unset;
}

.filterSideBar label {
    margin-left: .5rem;
}

.accordionContainer {
    box-shadow: unset !important;
    margin: unset !important;
}

.accordionDetails {
    padding: unset !important;
}

.listedJobsContainer {
    flex: 1;
    padding: 1rem;
    margin-block: 1.5rem;
    padding-bottom: 1rem;
}

.listedJobsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.listedJobsHeader h2 {
    color: var(--company_title_color);
    font-weight: 600;
    font-size: clamp(1.5rem, 3vw, 2rem);
}

.listedJobsHeader h4 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.jobsFilter {
    display: flex;
    gap: .75rem;
    align-items: center;
    justify-content: flex-end;
}

.jobsFilter h6 {
    color: var(--sub_header);
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: unset;
}

.filterBtn {
    border: unset;
    background-color: unset;
    color: var(--company_title_color);
    font-size: 1rem;
    font-weight: 500;
    width: 45%;
}

.jobsListCard {
    border: 1px solid var(--border_color);
    padding: 1.3rem 2.25rem;
    display: flex;
    justify-content: space-between;
    margin-block: 1.2rem;
}

.jobInfo {
    display: flex;
    align-items: flex-start;
    gap: 1.75rem;
}

.jobInfo img {
    width: 15%;
}

.jobInfo h2 {
    color: var(--company_title_color);
    font-weight: 600;
    font-size: 1.25rem;
}

.jobInfo h4 {
    color: var(--sub_header);
    font-weight: 400;
    font-size: 1rem;
}

.jobInfo h3 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 0.875rem;
    border-radius: 5rem;
    background-color: var(--bg_color);
    padding: 0.4rem 0.65rem;
}

.applyBtn {
    border: unset;
    color: var(--white_color);
    font-size: 1rem;
    font-weight: 700;
    background-color: var(--main_color);
    padding: 0.8rem 3rem;
    height: fit-content;
    align-self: center;
}