.editCompanyContainer {
    margin: 3rem;
}

.editCompanyContainer h2 {
    font-weight: 600;
    font-size: 2rem;
    color: var(--company_title_color);
}

.tab {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: var(--sub_header) !important;
}

@media screen and (max-width: 27rem) {
    .editProfileContainer {
        margin: 1.5rem;
    }
}