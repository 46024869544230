.openJobsContainer {
    background: url("../../images/assets/openJobs.jpg") center no-repeat;
    background-size: cover;
    padding: 1.5rem 2.4rem;
    margin-bottom: 2rem;
}

.openJobsContainer a {
    text-decoration: unset;
}

.openJobsHeader {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2.5rem;
}

.openJobsHeader h2 {
    color: var(--main_color);
    font-size: 2rem;
    font-weight: 600;
}

.openJobsHeader a {
    border: unset;
    background-color: unset;
    margin-right: 1rem;
    font-weight: 600;
    font-size: 1rem;
    color: var(--btn_color);
    padding-right: .5rem;
    text-decoration: unset;
}

.openJobsCardContainer {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-inline: 3rem;
    justify-content: center;
}

.companyInfoCard {
    display: flex;
    width: fit-content;
    gap: .85rem;
    background-color: var(--white_color);
    padding: 1.5rem;
}

.jobTitle h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--company_title_color);
}

.jobTitle h4 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--company_subTitle_color);
}

.jobDescription {
    display: flex;
    gap: 1rem;
}

.jobDescription h4 {
    width: fit-content;
    height: fit-content;
    color: var(--main_color);
    font-weight: 600;
    font-size: .85rem;
    border-radius: 10px;
    padding: 0.3rem 0.9rem;
    background-color: var(--bg_color);
}

@media screen and (max-width: 65rem) {
    .openJobsCardContainer {
        gap: .75rem;
        margin-inline: 1rem;
        justify-content: center;
    }

    .companyInfoCard {
        padding: .5rem;
    }
}

@media screen and (max-width: 27rem) {
    .jobTitle h2 {
        font-size: 1.25rem;
    }
}