.companyPhotosContainer {
    margin: 2rem .5rem;
}

.companyPhotosContainer h2 {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
}

.companyPhotosContainer h3 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.photoSection {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.photoHead {
    width: 40%;
}

.uploadContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1.5rem;
}

.profileImage {
    width: 23%;
    margin-top: .3rem;
    padding-right: 0.6rem;
}

.uploadImgSection {
    border: 2px dashed var(--main_color);
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: var(--background-color);
}

.uploadImgSection h4 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--company_subTitle_color);
}

.uploadImgSection span {
    color: var(--company_title_color);
}

.uploadImgSection h5 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.uploadLabel {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:50rem) {
    .companyPhotosContainer {
        margin: 1rem .5rem;
        width: auto;
    }

    .photoSection {
        justify-content: unset;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 45rem) {
    .photoHead {
        width: auto;
        margin-bottom: 1rem;
    }
}