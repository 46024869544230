.banner {
  height: 132px;
  background: rgba(254, 239, 221, 0.35);
  box-shadow: inset 11px 26px 3px -32px rgba(0, 0, 0, 0.25);
  position: relative;
}

.bannerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  position: absolute;
  left: 7.5%;
  top: 84px;
}
.detailsContent {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 48px auto 20px;
  padding-top: 20px;
  min-height: 30vh;
}
