.companiesListContainer {
    margin: 1.5rem 3rem;
    margin-bottom: 2.5rem;
}

.companiesListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.companiesListHeader h2 {
    color: var(--header_color);
    font-size: 3rem;
    font-weight: 500;
}

.companiesListHeader button {
    border: unset;
    background-color: unset;
    margin-right: 1rem;
    font-weight: 600;
    font-size: 1rem;
    color: var(--btn_color);
    padding-right: .5rem;
}

.companyInfoCardContainer {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-top: 0.8rem !important;
}

.companyInfoCard {
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: .85rem;
    /* border: 1px solid var(--secondary_color); */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1rem;
}

.companyInfoCardContainer a {
    text-decoration: none !important;
}

.companyLogo {
    display: flex;
    justify-content: space-between;
}

.companyLogo h5,
.companyPurpose h5 {
    width: fit-content;
    height: fit-content;
    color: var(--main_color);
    font-weight: 400;
    font-size: 1rem;
    border-radius: 10px;
    padding: 0.3rem 0.9rem;
    background-color: var(--bg_color);
}

.companyTitle h2 {
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--company_title_color);
}

.companyTitle h4 {
    font-weight: 400;
    font-size: 1.125rem;
    color: var(--company_subTitle_color);
    min-height: 7vh !important;
}

.companyPurpose h5 {
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.35rem 0.95rem;
}

.companyPurpose {
    min-height: 11vh !important;

}

.companyPurpose h5 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    /* Adjust this line-height according to your design */
    max-height: 4.5em;
    /* This should be line-height multiplied by 3 */
}


@media screen and (max-width: 45rem) {

    .companyInfoCardContainer {
        justify-content: center;
    }

    .companyInfoCard {
        width: 75%;
        padding: 1.5rem;
    }

    .companiesListContainer {
        margin: 1.75rem;
    }
}

@media screen and (max-width: 27rem) {
    .companiesListHeader {
        display: block;
        margin-bottom: 1rem;
    }

    .companyInfoCard {
        width: 100%;
        padding: 1rem;
    }
}