.textProfessional {
  color: var(--main_color);
}

.BorderDiv {
  /* border-bottom: 1px solid black !important; */
  padding: 10px !important;
  margin-bottom: 10px !important;
}

.CurrentJob {
  display: flex;
  flex-direction: column;
}

.CurrentJob span {
  color: #7c8493;
}

.languageColor {
  color: var(--main_color) !important;
  font-size: 18px !important;
}

.edu {
  color: var(--main_color) !important;
  font-size: 17px !important;
}

.educationContainer,
.skillsContainer,
.languageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.educationContent {
  width: 22rem;
}

.skillsContent,
.languageContent {
  width: fit-content;
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.textProfessional {
  font-size: 1.2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 1rem;
}

.certList {
  list-style: none;
  padding: 0;
}

.certItem {
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.certTitle {
  font-size: 1.1rem;
  color: #2c3e50;
  font-weight: 700;
  margin-bottom: 0.2rem;
}

.certOrganization {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
}

.certDate {
  font-size: 0.85rem;
  color: #95a5a6;
}

.certLink {
  font-size: 0.85rem;
  color: #3498db;
  text-decoration: none;
  margin: 0.5rem;
}

.certLink:hover {
  text-decoration: underline;
}

.BorderDiv {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.CurrentJob {
  font-weight: 500;
  color: #2c3e50;
}

.toggleButton {
  background-color: transparent;
  border: none;
  color: #3498db;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1rem;
}

.toggleButton:hover {
  text-decoration: underline;
}