.socialLinksContainer {
    margin: 2rem .5rem;
    width: 90%;
}

.socialLinksContainer h2 {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
}

.socialLinksContainer h3 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.personalDetailsSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.formSection {
    width: 50%;
}

.txtFieldContainer {
    width: 100%;
    padding-top: .5rem;
}

.fieldsName {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

.txtField {
    margin: 0 !important;
    /* border: 1px solid var(--border_color) !important; */
    color: var(--white_color) !important;
}

.saveBtnSection {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1rem;
    margin-top: 1rem;
    /* border-top: 1px solid var(--border_color); */
}

.saveBtnSection button {
    font-weight: 700;
    font-size: 1.125rem;
    border-radius: 0.625rem;
    padding: 0.875rem 2.5rem;
    background-color: var(--main_color);
    border: unset;
    color: var(--white_color);
}

.Basic_Information {
    width: 25% !important;
}

@media screen and (max-width:50rem) {
    .socialLinksContainer {
        margin: 1rem .5rem;
        width: auto;
    }

    .personalDetailsSection {
        justify-content: unset;
        gap: 2rem;
    }

    .formSection {
        width: auto;
    }
}

@media screen and (max-width: 38rem) {
    .personalDetailsSection {
        flex-direction: column;
        gap: 1.2rem;
    }

    .Basic_Information {
        width: 100% !important;
    }
}