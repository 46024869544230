.jobInfoContainer {
    margin: 4rem 2rem;
}

.jobInfoContainer h2 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 1.125rem;
}

.jobInfoContainer h3 {
    color: var(--sub_header);
    font-weight: 400;
    font-size: 1rem;
}

.stepsSec {
    padding-block: 1.5rem;
}

.basicInfoSec,
.jobTitleSec,
.employmentType,
.locationSec,
.salarySec,
.categoriesSec,
.skillsSec,
.timeSec {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
}

.jobTitleSec {
    display: flex;
    gap: 2.5rem;
}

.txtField {
    width: 30% !important;
    margin-left: 8rem !important;
    margin-top: 0 !important;
}

.employmentType,
.locationSec,
.salarySec,
.categoriesSec,
.skillsSec {
    display: flex;
    gap: 4rem;
}

.employmentSelection,
.locationSelection {
    padding-left: 3.5rem !important;
    margin-left: 3.5rem !important;
}

.salarySelection {
    display: flex;
    gap: .75rem;
    flex-direction: column;
    margin-left: 5rem;
    padding-left: 5rem;
}

.salaryInput {
    margin-left: 1rem !important;
    /* width: 50% !important; */
}

.categoriesSelection {
    margin-left: 0.8rem !important;
}

.selectFieldSmall {
    width: 50% !important;
    margin-top: .25rem !important;
}

.skillSelection {
    margin-left: 7.5rem;
}

.skillSelection {
    border: 1px solid var(--btn_border);
    padding: 0.625rem 1rem;
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--main_color);
    background-color: transparent;
}

.skillItem {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: .3rem .6rem;
    background-color: var(--bg_color);
    border: 1px solid var(--main_color);
    border-radius: 5px;
    color: var(--main_color);
}

.skillsContainer {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    margin-top: 0.8rem;
    margin-left: 7rem;
}

.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: var(--white_color);
    padding: 2rem;
    border: 1px solid var(--main_color);
    box-shadow: 1rem;
    outline: none;
    border-radius: .2rem;
}

.modalContent h2 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
}

.timeSec h2 {
    margin-bottom: 1rem;
}

.dateSelectContainer {
    width: 20%;
}

.dateSelectField {
    color: var(--header_color) !important;
}

.root {
    width: '100%';
}

.paper {
    padding: 16px;
    margin: 16px 0;
}

.basicInfoSec,
.jobTitleSec,
.employmentType,
.locationSec,
.salarySec,
.categoriesSec,
.skillsSec,
.timeSec {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
}

.checkboxLabel {
    margin-right: 16px;
}

.title {
    font-weight: 600;
    color: #263997;
}

.subTitle {
    color: grey;
}

.modal {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 400;
    background-color: 'white';
    border: '2px solid #000';
    box-shadow: 24;
    padding: 32px;
}

.jobInfoContainer {
    margin: 4rem 2rem;
}

.jobInfoContainer h2 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 1.125rem;
}

.jobInfoContainer h3 {
    color: var(--sub_header);
    font-weight: 400;
    font-size: 1rem;
}

.stepsSec {
    padding-block: 1.5rem;
}

.textField {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
}

.textFieldForSalary {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
    padding-top: 1.75rem;
    border-top: 1px solid var(--border_color);
    margin-top: 1.5rem;
}

.jobTitleSec {
    display: flex;
    gap: 2.5rem;
}

.txtField {
    width: 30% !important;
    margin-left: 8rem !important;
    margin-top: 0 !important;
}

.employmentType,
.locationSec,
.salarySec,
.categoriesSec,
.skillsSec {
    display: flex;
    gap: 4rem;
}

.employmentSelection,
.locationSelection {
    padding-left: 3.5rem !important;
    margin-left: 3.5rem !important;
}

.categoriesSelection {
    margin-left: 0.8rem !important;
}

.selectFieldSmall {
    width: 50% !important;
    margin-top: .25rem !important;
}

.skillSelection {
    margin-left: 7.5rem;
}

.skillSelection {
    border: 1px solid var(--btn_border);
    padding: 0.625rem 1rem;
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--main_color);
    background-color: transparent;
}

.skillItem {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: .3rem .6rem;
    background-color: var(--bg_color);
    border: 1px solid var(--main_color);
    border-radius: 5px;
    color: var(--main_color);
}

.skillsContainer {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    margin-top: 0.8rem;
    margin-left: 7rem;
}

.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: var(--white_color);
    padding: 2rem;
    border: 1px solid var(--main_color);
    box-shadow: 1rem;
    outline: none;
    border-radius: .2rem;
}

.modalContent h2 {
    color: var(--main_color);
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
}

.timeSec h2 {
    margin-bottom: 1rem;
}

.dateSelectContainer {
    width: 20%;
}

.dateSelectField {
    color: var(--header_color) !important;
}

.responsiveDiv {
    width: 50% !important;
}

.SelectResponsive {
    width: 35%;
}

.ResponsivePostWidth {
    width: 75% !important;
}

@media screen and (max-width: 45rem) {
    .stepperStep {
        flex-direction: column !important;
    }

    .responsivePost {
        flex-direction: column !important;
    }

    .responsiveDiv {
        width: 100% !important;

    }

    .salarySelection {
        width: 100% !important;
        margin-left: 0px !important;
        padding-left: 0px !important;
    }

    .SelectResponsive {
        width: 100%;
    }

    .ResponsivePostWidth {
        width: 100% !important;
    }

}

.fullWidthField {
    width: 100% !important;
}

/* Update your responsive classes to ensure consistency */
.responsiveDiv,
.ResponsivePostWidth,
.fullWidthFieldContainer {
    width: 75% !important;
}

@media screen and (max-width: 45rem) {
    .responsivePost {
        flex-direction: column !important;
    }

    .responsiveDiv,
    .ResponsivePostWidth,
    .fullWidthFieldContainer {
        width: 100% !important;
    }
}

.button {
    width: 50% !important;
}