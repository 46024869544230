.jobsListCard {
  border: 1px solid var(--border_color);
  padding: 1.3rem 2.25rem;
  display: flex;
  justify-content: space-between;
  margin-block: 1.2rem;
}
.jobInfo {
  display: flex;
  align-items: flex-start;
  gap: 1.75rem;
}

.jobInfo h2 {
  color: var(--company_title_color);
  font-weight: 600;
  font-size: 1.25rem;
}

.jobInfo h4 {
  color: var(--sub_header);
  font-weight: 400;
  font-size: 1rem;
}

.jobInfo h3 {
  color: var(--main_color);
  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 5rem;
  background-color: var(--bg_color);
  padding: 0.4rem 0.65rem;
}

.applyBtn {
  border: 1px solid var(--main_color);
  color: var(--main_color);
  font-size: 1rem;
  font-weight: 700;
  background-color: var(--white_color);
  padding: 0.5rem 2rem;
  height: fit-content;
  align-self: center;
  border-radius: 25px;
}
