.editProfileContainer {
    margin: 3rem;
}

.editHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
}

.editHeader h2 {
    font-weight: 600;
    font-size: 2rem;
    color: var(--company_title_color);
}

.editHeader button {
    font-weight: 700;
    font-size: 1rem;
    color: var(--main_color);
    padding: 0.75rem 1.5rem;
    border: 1px solid #263A96;
    border-radius: 5px;
}

.tab {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: var(--sub_header) !important;
}

@media screen and (max-width: 27rem) {
    .editProfileContainer {
        margin: 1.5rem;
    }

    .editHeader {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        gap: 1rem;
        flex-wrap: wrap;
        
    }
}