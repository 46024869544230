.vacancyForm {
    background-color: "white";
    min-height: "50vh";
}

.overlappingTitle {
    color: #1B307F;
    background: #FEF7EF;
    box-shadow: 0px 4px 20px rgba(242, 153, 74, 0.5);
    font-weight:600; 
    padding-right:20px; 
    padding-left:20px; 
    position:absolute;
    top:-50px;
    left: 20px;
    right: 20px;
    margin: 0 auto;
}

@media only screen and (min-width: 768px) {
    .overlappingTitle {
        top:-20px;
        left:50px;
        right: unset;
    }
}

.overlappingSubmit {
    background: #1B307F;
    border: #1B307F;
    outline: #1B307F;
    color: #FFFFFF;
    border-radius: 2.94533px;
    font-weight:600; 
    padding: 15px 34px 10px 33px;
    position:absolute;
    bottom: -25px;
    right:50px;
}

.vacancyForm label {
    color:#313144;
    margin-bottom: 10px;
}

.vacancyForm input , .vacancyForm textarea, .vacancyForm select{
    display: block;
    width: 100%;
    background: #FFFFFF;
    border: 0.748641px solid #263997;
    border-radius: 5.98913px;
    padding: 12px 10px;
}

.vacancyForm select {
    -webkit-appearance: none;
    appearance: none;
}

.selectWrapper {
    position: relative;
}
  
.selectWrapper::after {
    content: '\f0dd';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    font-size: 1rem;
    top: 10px;
    right: 20px;
    position: absolute;
}

.vacancyForm input:focus-visible ,
.vacancyForm textarea:focus-visible,
.vacancyForm select:focus-visible {
    outline: 0.748641px solid #263997;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
}