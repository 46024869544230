.heroSectionContainer {
  background: url("../../images/assets/heroBG.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
}

.heroSectionContainer>div {
  width: 50%;
  padding-top: 3rem;
  padding-bottom: 13rem;
  margin-left: 3rem;
}

.heroSectionContainer h2 {
  font-weight: 600;
  font-size: clamp(2rem, 4vw + 1rem, 4.5rem);
  line-height: 5rem;
  color: #F5F5F5;
}

.heroSectionContainer h2>span {
  color: #BDC9FF;
}

.heroSectionContainer img {
  padding-block: 1rem;
}

.heroSectionContainer h5 {
  font-size: 1.45rem;
  font-weight: 500;
  color: #FFFFFF;
}

.heroSectionContainer h5>span {
  font-weight: 600;
  color: #FFDA6E;
}



@media screen and (max-width: 40rem) {
  .heroSectionContainer>div {
    margin-left: 1rem;
  }

  .heroSectionContainer {

    height: 40vh !important;
    background-color: #263C96 !important;
    background-image: none !important;
  }

  .heroSectionContainer div {
    width: 100% !important;
  }

  .heroSectionContainer img {
    display: none !important;
  }

  .heroSectionContainer h2 {
    font-size: 2rem !important;
    line-height: 1 !important;
  }

}