.signInContainer {
  display: flex;
  overflow: hidden;
}

.imgContainer {
  flex: 1;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 90vh;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.formContainer {
  flex: 1;
  width: 50%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tab {
  color: var(--main_color) !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  width: 50% !important;
}

.activeTab {
  background-color: #E9EBFD !important;
  color: var(--main_color) !important;
  border-bottom: none !important;
}

.toggleContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.tabsContainer {
  width: 80% !important;
}

.tabsContent {
  width: 100% !important;
}

@media only screen and (max-width: 48rem) {
  .imgContainer {
    display: none;
  }

  .formContainer {
    width: 100%;
    justify-content: center;
  }
}