.contactLogo {
    display: flex;
    align-items: center;
    width: 47%;
    height: 100%;
    overflow: hidden;
}

.contactLogo img {
    width: 100%;
    object-fit: cover;
    height: 89.5vh;
}

.companyRegisterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.registerSection {
    width: 50%;
    padding: 1rem;
    margin: 1rem;
}

.test {
    display: flex;
    gap: 1.2rem;
}

.selectContainer {
    width: 60% !important;
}

.selectFieldSmall {
    margin: 0 !important;
    border: 1px solid var(--border_color) !important;
    color: var(--white_color) !important;
}

.txtFieldContainer {
    margin-block: .75rem;
}

.saveBtnSection {
    color: var(--white_color);
    border: unset;
    font-weight: 700;
    font-size: clamp(0.9rem, 1vw, 1.2rem);
    margin-bottom: .6rem;
    background-color: var(--main_color);
    padding: .75rem 1.5rem;
    width: 50%;
}

.selectSizeContainer {
    width: 70%;
}

.selectSize {
    width: 100%;
    height: 3.3rem;
    border: 1px solid var(--sub_header);
    border-radius: 0.5rem;
    padding-inline: .5rem;
    font-size: 1rem;
    color: var(--header_color);
    background-color: transparent;
}

.alertMessage {
    color: red;
    font-weight: 600;
}

@media only screen and (max-width: 48rem) {
    .companyRegisterContainer {
        flex-direction: column;
    }

    .contactLogo {
        height: 80vh;
        width: 100%;
    }

    .registerSection {
        width: 100%;
        padding: 1.5rem;
        margin: auto;
    }
}

.selectSize {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.selectSize option {
    background-color: #fff;
    color: #333;
}