.cvContainer {
    margin: 2rem .5rem;
    width: 90%;
}

.cvContainer h2 {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
}

.cvContainer h3 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--sub_header);
}

.personalInfoHeader {
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--border_color);
    margin-bottom: 1.5rem;
}

.personalInfoHeader h2 {
    font-size: 1.125rem;
}

.personalDetailsSection {
    display: flex;
    margin-bottom: 1.5rem;
    gap: 10rem;
}

.formSection {
    width: 60%;
    gap: 2rem;
}

.fileUploadSection {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
}

.fieldsName {
    color: var(--main_color);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

.txtField,
.selectFieldLarge,
.selectFieldSmall {
    margin: 0 !important;
    color: var(--header_color) !important;
}

.uploadBtnContainer {
    display: flex;
    align-items: center;
    border: 1px solid var(--border_color);
    padding-block: 0.45rem;
    padding-left: .5rem;
}

.uploadBtn {
    color: black;
    box-shadow: 0px 3.43px 6.85px 0px #3232470F;
    border: 0.86px solid #6A6A65;
    font-weight: 400;
    background: #F5F5F5;
    border-radius: 2.75px;
    padding: 0.4rem;
}

.saveBtnSection {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1rem;
    margin-top: 1rem;
    /* border-top: 1px solid var(--border_color); */
}

.saveBtnSection button {
    font-weight: 700;
    font-size: 1.125rem;
    border-radius: 0.625rem;
    padding: 0.875rem 2.5rem;
    background-color: var(--main_color);
    border: unset;
    color: var(--white_color);
}

@media screen and (max-width:50rem) {
    .personalInfoContainer {
        margin: 1rem .5rem;
        width: auto;
    }

    .personalDetailsSection {
        justify-content: space-between;
        gap: 2rem;
    }

    .formSection {
        width: 80%;
    }
}

@media screen and (max-width: 38rem) {
    .personalDetailsSection {
        flex-direction: column;
        gap: 1.2rem;
    }
}