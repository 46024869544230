/* .dimmed-photo {
    filter: grayscale(100%) brightness(80%);
} */

.partners-title {
    color: var(--main_color) !important;
    font-size: 1.5rem !important;
    margin-left: 2rem !important;
    font-weight: 700 !important;
    text-align: left !important;
    margin-bottom: 0.5rem !important;
}